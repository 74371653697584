<template>
  <div class="bg-white p-4 rounded-md shadow-md mt-10">
    <h2 class="text-2xl font-bold mb-5">Reset Password</h2>
    <span
      v-if="message !== ''"
      :class="`mb-3 block text-xs font-semibold ${
        error ? 'text-red-500' : 'text-green-500'
      }`"
      >{{ message }}</span
    >
    <a-form-model
      ref="passwordToken"
      :rules="rules"
      layout="vertical"
      :model="form"
    >
      <a-form-model-item has-feedback label="Password" prop="password">
        <a-input-password
          v-model="form.password"
          :disabled="signing_in"
          placeholder="Password"
        />
      </a-form-model-item>
      <a-form-model-item
        has-feedback
        label="Confirm Password"
        prop="confirm_password"
      >
        <a-input-password
          v-model="form.confirm_password"
          :disabled="signing_in"
          placeholder="Confirm Password"
        />
      </a-form-model-item>
      <a-form-model-item>
        <a-button :loading="signing_in" type="primary" block @click="onSubmit">
          Reset
        </a-button>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>
<script>
import authApi from '../../api/auth'
export default {
  name: 'ResetPassword',
  layout: 'auth',
  data() {
    const passwordValidator = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Please input the password'))
      } else {
        if (this.form.confirm_password !== '') {
          // console.log('valudate')
          this.$refs.passwordToken.validateField('confirm_password')
        }
        callback()
      }
    }
    const confirmPasswordValidator = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Please input the password again'))
      } else if (value !== this.form.password) {
        callback(new Error("Two inputs don't match!"))
      } else {
        callback()
      }
    }
    return {
      error: false,
      message: '',
      signing_in: false,
      form: {
        password: '',
        confirm_password: '',
      },
      rules: {
        password: [
          {
            message: 'Password cannot be empty',
            required: true,
          },
          {
            message: 'Password must be at least 6 characters',
            min: 6,
          },
          {
            trigger: 'change',
            validator: passwordValidator,
          },
        ],
        confirm_password: [
          {
            required: true,
            message: 'Enter your password again',
          },
          {
            validator: confirmPasswordValidator,
            trigger: 'change',
          },
        ],
      },
    }
  },
  head: {
    title: 'Reset Password | Legal Naija',
  },
  created() {
    this.token = this.$route.params.password_token
  },
  methods: {
    async recoverPassword() {
      this.message = ''
      this.signing_in = true
      const req = await authApi(this.axios).resetPassword({
        token: this.token,
        password: this.form.password,
        confirmPassword: this.form.confirm_password,
      })
      if (req.error) {
        this.error = true
        this.message = req.message
        this.signing_in = false
        return
      }
      this.error = false
      this.message = req.message + '. You will be redirected to login'
      this.signing_in = false
      setTimeout(() => {
        this.$router.push('/signin')
      }, 4000)
    },
    onSubmit() {
      // console.log(this.$refs.passwordToken.validate())
      this.$refs.passwordToken.validate((valid) => {
        if (valid) {
          this.recoverPassword()
        } else {
          return false
        }
      })
    },
  },
}
</script>
